<template>
  <FullViewReports />
</template>

<script>
  import FullViewReports from '../../src/pages/fullViewReport/FullviewReports.vue';

  export default {
    components: {
      FullViewReports
    },
    name: 'FullViewReportCabinetAlerts'
  };
</script>